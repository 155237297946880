@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

html {
 overflow-y: scroll;
}

.container {
    // `layout` container functions as a row
    @include clearfix;
    @include grid-column-row(0);
    margin-top: rem-calc(36);
}
.main-col {
    // Mobile-first: make `layout-container` full-width
    @include grid-column;
    margin-top: 32px;

    // On medium-up size, make `layout-container` 9 columns wide
    @include breakpoint(medium up) {
        @include grid-column(9);
        padding-left: 0!important;
    }
}
.side-col {
    @include grid-column-row(0);

    // On medium-up size, make `layout-sidebar` 3 columns wide
    @include breakpoint(medium up) {
        @include grid-column(3);
        padding-right: 0!important;
    }
}
.top-logo {
  @include breakpoint(small only) {
    @include grid-column(6);
  }
}
.header-pc-nav-section {
  margin-top: 20px;
  &__li {
    line-height: 1;
    margin-top: 1rem;
  }
}
.mobile-menu-button {
  background-color: #eaeaea;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: #333;
  height: auto;
  line-height: 1;
  margin-top: 8px;
  margin-right: 8px;
  padding: 16px;
  transition: .1s;
  vertical-align: top;
  & i {
    font-size: 1.6rem;
    margin-right: 8px;
    vertical-align: middle;
  }
  & span {
    font-size: 1.6rem;
    vertical-align: middle;
  }
  &:hover {
    background-color: #f8f8f8!important;
    border-color: #c6c6c6;
    color: #222;
    text-decoration: none;
  }
  &:active {
    -ms-transform: translateY(1px);
    -webkit-transform: translateY(1px);
    transform: translateY(1px);
  }
  @include breakpoint(medium up) {
    display: none;
  }
}
.mobile-menu-close-button {
  background-color: #eaeaea;
  border: 1px solid #bbb;
  border-radius: 3px;
  color: #333;
  text-align: center;
  height: auto;
  line-height: 1;
  margin-right: 20px!important;
  margin-top: 44px;
  padding: 16px 32px 16px 16px;
  transition: .1s;
  vertical-align: top;
  & i {
    font-size: 1.6rem;
    margin-right: 8px;
    vertical-align: middle;
  }
  & span {
    font-size: 1.8rem;
    vertical-align: middle;
  }
}
.mobile-nav-section {
  @include grid-column(6);
  text-align: right;
}
.mobile-nav {
  &__li {
    list-style-type: none;
    border-bottom: 1px dotted #cacaca;
    font-size: 1.6rem;
    text-align: left;
  }
  &__a {
    width: 100%;
    color: #0b850b;
    display: block;
    padding: 16px 0px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

footer {
  clear: both;
  margin-top: 80px;
  text-align: center;
}


.header-pc-nav-section {
    display: none;
    @include breakpoint(medium up) {
      display: block;
    }
}

/* common */
.h-01 {
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 1.3;
    &__date {
        display: inline-block;
        font-size: 85%;
        padding-top: 8px;
    }
}
.h-02 {
    margin-top: 40px;
}
.h-03 {
    background-color: #e7d8a0;
    color: 333;
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 40px;
    padding: 8px;
}
.h-04 {
    color: #6e5129;
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 40px;
}
.h-05 {
    font-size: 1.6rem;
    font-weight: bold;
    border-left: 4px solid #333;
    margin-top: 24px;
    padding-left: 4px;
}
.ul-01__li {
    margin-top: 16px;
}

.table-01 {
  @include table(odd);
  &__th {
    border-top: 1px solid #ddd;
    min-width: 4.5em;
    text-align: left;
    vertical-align: top;
  }
  &__td {
    border-top: 1px solid #ddd;
    text-align: left;
    vertical-align: top;
  }
}

.p-01 {
  margin-bottom: 0!important;
}
.p-first {
  margin-top: 40px;
}

.to-map {
  font-size: 1rem;
  text-decoration: none;
}
.to-map i{
  font-size: 1rem;
}
.to-map:hover {
  text-decoration: underline;
}


/* home */
.home-images {
    padding: 0;
    margin: 40px auto 0;
    text-align: center;
}
.home-images__figure {
  display: inline-block;
  margin-top: 2%;
  margin-right: 2%;
  vertical-align: top;
  // width: 30%;
  width: calc((100% - 2% * 2) / 3);
  &:nth-child(3n) {
    margin-right: 0;
  }
}
.home-images__img {
}
.home-images__caption {
  font-size: 1rem;
}

.top-news {
  @include callout-base;
  background-color: $light-gray;
  margin-top: 40px;
  margin-bottom: 0;
}
.top-message {
  margin-top: 24px;
}

.div-02 {
  padding: 8px;
  border: 1px solid #666;
  margin-top: 24px;
}

.news-02 {
  padding: 1em;
  border: 1px solid #666;
  margin-top: 24px;
}
.news-02__dt {
  color: #6e5129;
}
.news-02__dt:before {
  content: '★';
}
.news-02__dd {
  margin-left: 1em;
}
/* about */
.tatemono-setsubi {
  &__dt {
    margin-top: 24px;
  }
  &__img {
    width: 48%;
    @include breakpoint(medium up) {
      width: inherit;
    }
    &:first-child {
        margin-right: 8px;
    }
  }
}
@media all and (max-width:539px) {
    .tatemono-setsubi__img {
    }
}

.table-event {
  @include table(odd);
  vertical-align: top;
  &__month {
    border-top: 1px solid #ddd;
    width: 3em
  }
  &__col-01 {
    border-top: 1px solid #ddd;
    width: 12em;

  }
  &__col-02 {
    border-top: 1px solid #ddd;
    width: 12em;

  }
  &__col-03 {
    border-top: 1px solid #ddd;
    width: 12em;

  }
}

/* home画像要管理画面 */
.acf-gallery .acf-gallery-attachment {
  width: 32%!important;
}

.acf-gallery .acf-gallery-attachment .thumbnail img {
  width: 100%;
}


/* */
.iframe-caption {
  text-align: right;
}
.link-open-in-another-window:after {
  font-family: 'Material Icons';
  content: "\e89e";
}
